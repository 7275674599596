"use client";
import { withSentry } from "@sentry/remix";
import { ThemeProvider, useTheme, PreventFlashOnWrongTheme } from "remix-themes";

import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useLoaderData,
  Link,
  useRouteError,
} from "@remix-run/react";

import { getUser, themeSessionResolver } from "./session.server";
// import tailwindStylesheetUrl from '~/style.css?url';

// // Test this
// const tailwindStylesheetUrl = '~/styles/tailwind.css?url';

import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import { AppProvider } from "./context/app-provider";
import { LogError } from "./utils/log";
import { Alert, ArrowLeft } from "./components/Icons";
import { ReaderProvider } from "./context/reader-provider";
import mainStyles from "~/styles/main.css?url";

export const links = () => {
  return [
    { rel: "stylesheet", href: mainStyles },
    { rel: "stylesheet", href: "https://use.typekit.net/jzd8whz.css" },
    { rel: "manifest", href: "/manifest.json" },
  ];
};

export const meta = () => {
  return [
    { charset: "utf-8" },
    { title: "Mindsera" },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
    {
      name: "theme-color",
      content: "#fff",
      media: "(prefers-color-scheme: light)",
    },
    {
      name: "theme-color",
      content: "#1f2937",
      media: "(prefers-color-scheme: dark)",
    },
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "robots", content: "noindex" },
    // og: Open Graph
    { property: "og:title", content: "Login to Mindsera" },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://beta.mindsera.com" },
    {
      property: "og:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
    { property: "og:description", content: "" },
    // twitter
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@mindseraHQ" },
    { name: "twitter:creator", content: "@mindseraHQ" },
    { name: "twitter:title", content: "Login to Mindsera" },
    { name: "twitter:description", content: "" },
    {
      name: "twitter:image",
      content: "https://beta.mindsera.com/images/mindsera-og.jpg",
    },
  ];
};

export async function loader({ request }) {
  const { getTheme } = await themeSessionResolver(request);

  return json({
    user: await getUser(request),
    theme: getTheme(),
  });
}

function App() {
  const location = useLocation();
  const data = useLoaderData();
  const [theme] = useTheme();

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-5VZGRX3" });
  }, [location]);

  return (
    <html
      lang="en"
      className={`h-full ${data?.user?.betaEnabled ? "bg-white" : "bg-gray-100"} dark:bg-gray-900`}
      data-theme={theme ?? ""}
    >
      <head>
        <Meta />
        <Links />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
      </head>
      <body className={data?.user?.betaEnabled ? "relative h-full bg-white" : "relative h-full"}>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    LogError(error);
  }, []);

  const [hasSent, setHasSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const sendErrorReport = async () => {
    setIsSending(true);

    const res = await fetch("/api/report", {
      method: "POST",
      body: JSON.stringify({ error }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();

    if (json.success) {
      setHasSent(true);
      setIsSending(false);
    }
  };

  return (
    <html className="h-full">
      <head>
        <title>Oops! An error has occurred.</title>
        <Meta />
        <Links />
      </head>
      <body className="relative h-full">
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col overflow-auto bg-gray-100 p-3 dark:bg-gray-900 md:p-4">
          <div className="relative flex flex-1 flex-col items-center justify-center space-y-12 rounded-xl bg-white p-4 text-center shadow-[0_5px_25px_rgba(0,0,0,0.15)] dark:bg-gray-800 md:shadow-md">
            <Alert className="text-[57px] text-[#EF4444]" />
            <div className="text-[25px] leading-9 text-[#6C727F] md:text-[27px]">
              Oops! An error has occurred. Return and attempt the action again. If the problem
              persists, please{" "}
              <a href="https://help.mindsera.com/contact-us" className="underline">
                contact support
              </a>{" "}
              for assistance.
            </div>
            <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-x-12 sm:space-y-0">
              {hasSent ? (
                <div className="text-xl">Report sent</div>
              ) : (
                <button
                  disabled={isSending}
                  onClick={sendErrorReport}
                  className="flex items-center text-xl"
                >
                  Send error report
                  <ArrowLeft className="ml-2 rotate-180" />
                </button>
              )}
              <Link to="/" className="flex items-center text-xl">
                Go to dashboard
                <ArrowLeft className="ml-2 rotate-180" />
              </Link>
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

function AppWithProviders() {
  const data = useLoaderData();
  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/action/set-theme">
      <AppProvider>
        <ReaderProvider>
          <App />
        </ReaderProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default withSentry(AppWithProviders);
